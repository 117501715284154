import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../layout';
import { HeaderSimple } from '../components/headers';
import SEO from '../components/SEO';

const Success = () => (
  <Layout>
    <SEO title="About" path="/success" />
    <Helmet>
      <title>Success Page</title>
      <meta name="description" content="Success Page" />
    </Helmet>
    <div id="main" className="container min-vh-60">
      <HeaderSimple title="Thank you" subTitle="We will get back at you shortly!" />
    </div>
  </Layout>
);

export default Success;
